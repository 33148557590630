.noteCardData .MuiList-padding {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-bottom: -15px !important;
}
.ptsTableHeader {
  margin: 0.5em -1.1em -1.5em -1.1em;
}

.noteHeading {
  border-right: 1px solid transparent !important;
}

.noteTableHeading th {
  font-weight: bolder !important;
  background-color: #ccd1d1;
  text-transform: none !important;
}

.appointmentTab .nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  background: #005476;
  color: #fff;
  margin: 5px;
}
.appointmentTab .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: none;
}
.appointmentTab .tab-content {
  background: transparent;
  padding: 0px;
  margin: 0em 0em 0em 0.4em;
}
.appointmentList_filter .card {
  background: transparent;
  border-radius: 4px;
  box-shadow: 0 0 10px transparent;
  border: 0;
  margin-bottom: 20px;
}

.appointmentList_filter .MuiFormLabel-root {
  color: rgba(0, 0, 0, 0.54);
  padding: 0px 0px;
  font-size: 1rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 2;
  letter-spacing: 0.00938em;
}

.appointmentList_filter .PrivateNotchedOutline-root-1 {
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0 8px;
  overflow: hidden;
  position: absolute;
  border-style: solid;
  border-width: 1px;
  border-radius: inherit;
  pointer-events: none;
}

.appointmentList_filter .PrivateNotchedOutline-root-5 {
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0 8px;
  overflow: hidden;
  position: absolute;
  border-style: solid;
  border-width: 1px;
  border-radius: inherit;
  pointer-events: none;
}

.MuiTableSortLabel-root.MuiTableSortLabel-active {
  color: #fff !important;
}

.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active
  .MuiTableSortLabel-icon {
  color: #fff !important;
  opacity: 1;
}

.blink_me {
  font-weight: bolder;
  color: red;
}

.appointmentTab .MuiTableRow-root.MuiTableRow-hover:hover {
  background-color: lightgrey !important;
}
