// * {
//   margin: 0px;
//   padding: 0px;
//   box-sizing: border-box;
//   font-family: Arial, Helvetica, sans-serif;
// }
.banner-container {
  display: block;
  align-items: center;
  justify-content: center;
}

.bannerRow {
  // background: lightblue;
  border-radius: 5px;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 5px 10px rgb(228, 222, 222);
  overflow: hidden;
}
.banner{
  // background: lightblue;
  border-radius: 5px;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 5px 10px rgb(228, 222, 222);
  overflow: hidden;
}

.bannerRow .MuiInputBase-input {
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  height: 1.8em;
  margin: 0;
  display: block;
  padding: 6px 0 7px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
}

.banner-container .banner .shoe {
  flex: 0 1 auto;
  padding: 15px;
  text-align: center;
}

.banner-container .banner .shoe img {
  // width: 100%;
  height: 150px;
}
.banner-container .banner .content {
  flex: 0 1 auto;
  text-align: center;
  padding: 10px;
  // text-transform: uppercase;
  color: #fff;
}

.banner-container .banner .content h4 {
  color: #fff;
  font-size: 25px;
}
.banner-container .banner .content h3 {
  color: #fff;
  font-size: 40px;
}
.banner-container .banner .content p {
  color: #f4d03f;
  font-size: 20px;
  padding: 10px 0;
  line-height: 0px;
}
.banner-container .banner .content .btn {
  display: block;
  height: 40px;
  width: 150px;
  line-height: 20px;
  background: #fff;
  color: red;
  margin: 5px auto;
  text-decoration: none;
}

/* ---------*/
.banner-container .banner .women {
  position: relative;
  bottom: -3px;
  padding: 10px;
  flex: 0 1 auto;
}

.banner-container .banner .women img {
  width: 100%;
}

/*--- media query apply here */
@media All and (min-width: 768px) /*screen and (min-width: 480px) */ {
  .banner-container .banner {
    display: flex;

    flex: 0 1 auto;
  }
}

@media All and (max-width: 768px) /*screen and (min-width: 480px) */ {
  .banner-container .banner {
    display: flex;
    flex-wrap: wrap;
    flex: 0 1 auto;
  }
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  // float:left;
}


.fileUploadBtn {
  border: 2px solid gray;
  color: gray;
  background-color: white;
  padding: 3px 5px;
  border-radius: 8px;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.content .MuiInputBase-input {
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  height: 1.576em;
  margin: 0;
  display: block;
  padding: 12px 0 7px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
}