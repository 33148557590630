.ptsHeader {
  min-height: 3em;
  align-items: flex-end;
  box-shadow: 1px 1px 5px #524f4f;
  background: #fff;
  padding: 0.5em;
  margin-top: -1.2em;
  background-image: linear-gradient(#fff 0%, #f1eded 100%);
  position: fixed;
  width: 87%;
  z-index: 99;
}

@media screen and (min-width: 992px) {
  .side-menu-collapsed .ptsHeader {
    min-height: 3em;
    align-items: flex-end;
    box-shadow: 1px 1px 5px #524f4f;
    background: #fff;
    padding: 0.5em;
    margin-top: -1.2em;
    background-image: linear-gradient(#fff 0%, #f1eded 100%);
    position: fixed;
    width: 95%;
    z-index: 11;
  }
}

.homeHeder {
  min-height: 3em;
  align-items: flex-end;
  box-shadow: 1px 1px 5px #524f4f;
  background: #fff;
  padding: 0.5em;
  margin-top: -1.2em;
  background-image: linear-gradient(#fff 0%, #f1eded 100%);
  position: fixed;
  width: 101%;
  z-index: 2;
}

// @media screen and (max-width: 992px) {
//   .ptsHeader {
//     min-height: 3em;
//     align-items: flex-end;
//     box-shadow: 1px 1px 5px #524f4f;
//     background: #fff;
//     padding: 0.5em;
//     margin-top: -1.2em;
//     background-image: linear-gradient(#fff 0%, #f1eded 100%);
//     position: fixed;
//     width: 105%;
//     z-index: 11;
//   }
// }
