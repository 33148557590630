.planPriceDropDown {
    width: 80%;
    padding: 10px;
    border-radius: 6px;
}

.pricing-title p {
    margin: 0;
    margin: 0;
    font-size: 36px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    color: #2a2c39;
}

.contactSection-title {
    padding-bottom: 40px;
}

.pricing-title h2 {
    font-size: 14px;
    font-weight: 500;
    padding: 0;
    line-height: 1px;
    margin: 0 0 5px 0;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #aaaaaa;
    font-family: 'Poppins', sans-serif;
}

.pricing-title h2::after {
    content: '';
    width: 120px;
    height: 1px;
    display: inline-block;
    background: #fd9042;
    margin: 4px 10px;
}

.pricingModal .modal-content {
    padding: 0px !important;
}

.pricingModal ul {
    list-style: disc;
}

.pricingModal p {
    font-size: 17px;
}

.pricingModal .modal-title {
    margin-left: auto;
}

#price {
    text-align: center;
}

.plan {
    display: inline-block;
    margin: 10px 1%;
    font-family: 'Lato', Arial, sans-serif;
}

.plan-inner {
    background: #fff;
    margin: 0 auto;
    min-width: 280px;
    max-width: 100%;
    position: relative;
    min-height: 200px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.entry-title {
    background: #53cfe9;
    height: 140px;
    position: relative;
    text-align: center;
    color: #fff;
    margin-bottom: 30px;
}

.entry-title>h3 {
    background: #20bada;
    font-size: 20px;
    padding: 5px 0;
    text-transform: uppercase;
    font-weight: 700;
    margin: 0;
}

.entry-title .price {
    position: absolute;
    bottom: -25px;
    background: #20bada;
    height: 95px;
    width: 95px;
    margin: 0 auto;
    left: 0;
    right: 0;
    overflow: hidden;
    border-radius: 50px;
    border: 5px solid #fff;
    line-height: 80px;
    font-size: 28px;
    font-weight: 700;
}

.price span {
    position: absolute;
    font-size: 23px;
    bottom: 4px;
    left: 30px;
    font-weight: 400;
}

.entry-content {
    color: #323232;
}

.entry-content ul {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;
}

.entry-content li {
    border-bottom: 1px solid #e5e5e5;
    padding: 10px 0;
}

.entry-content li:last-child {
    border: none;
}

.plan-inner_free_plan_button_plan_card_bottom {
    padding: 2em 0 2em 0;
    text-align: center;
    margin-top: 4em;
}

.plan-inner_free_plan_button_plan_card_bottom a {
    background: #75ddd9;
    padding: 10px 30px;
    color: #fff !important;
    text-transform: uppercase;
    font-weight: 700;
    text-decoration: none;
}

.plan-inner_basic_plan_button_plan_card_bottom {
    padding: 2em 0 2em 0;
    text-align: center;
    margin-top: 4em;
}

.plan-inner_basic_plan_button_plan_card_bottom a {
    background: #dd4b5e;
    padding: 10px 30px;
    color: #fff !important;
    text-transform: uppercase;
    font-weight: 700;
    text-decoration: none;
}

.plan-inner_business_plan_button_plan_card_bottom {
    padding: 0.5em 0 2em 0;
    text-align: center;
}

.plan-inner_business_plan_button_plan_card_bottom a {
    background: #3772aa;
    padding: 10px 30px;
    color: #fff !important;
    text-transform: uppercase;
    font-weight: 700;
    text-decoration: none;
}

.plan-inner_free_plan_card_plan_card_bottom .entry-title {
    background: #75ddd9;
}

.plan-inner_free_plan_card_plan_card_bottom .entry-title>h3 {
    background: #44cbc6;
}

.plan-inner_free_plan_card_plan_card_bottom .price {
    background: #44cbc6;
}

.plan-inner_business_plan_card_plan_card_bottom .entry-title {
    background: #4484c1;
}

.plan-inner_business_plan_card_plan_card_bottom .entry-title>h3 {
    background: #3772aa;
}

.plan-inner_business_plan_card_plan_card_bottom .price {
    background: #3772aa;
}

.plan-inner_basic_plan_card_plan_card_bottom .entry-title>h3 {
    background: #dd4b5e;
}

.plan-inner_basic_plan_card_plan_card_bottom .entry-title {
    background: #f75c70;
}

.plan-inner_basic_plan_card_plan_card_bottom .price {
    background: #dd4b5e;
}

#price {
    text-align: center;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
}

.plan {
    display: inline-block;
    margin: 10px 1%;
    font-family: 'Lato', Arial, sans-serif;
}


.defaultCard .grid {
    list-style: none;
    margin-left: 0px !important;
}

@import url("https://fonts.googleapis.com/css?family=Space+Mono:400,400i,700,700i");

.CI-Card .card {
    width: 320px;
    height: 190px;
    -webkit-perspective: 600px;
    -moz-perspective: 600px;
    perspective: 600px;
    border-radius: 8px;
}

.CI-Card .card__part {
    box-shadow: 1px 1px #aaa3a3;
    top: 0;
    position: absolute;
    z-index: 1000;
    left: 0;
    display: inline-block;
    width: 320px;
    height: 190px;
    background-image: url("https://image.ibb.co/bVnMrc/g3095.png"),
        linear-gradient(to right bottom,
            #fd696b,
            #fa616e,
            #f65871,
            #f15075,
            #ec4879);
    /*linear-gradient(to right bottom, #fd8369, #fc7870, #f96e78, #f56581, #ee5d8a)*/
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 8px;

    -webkit-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -moz-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -ms-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -o-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
}

.CI-Card .card__part1 {
    box-shadow: 1px 1px #aaa3a3;
    top: 0;
    position: absolute;
    z-index: 1000;
    left: 0;
    display: inline-block;
    width: 320px;
    height: 190px;
    /* background-image: url("https://image.ibb.co/bVnMrc/g3095.png"),
    linear-gradient(
      to right bottom,
      #fd696b,
      #fa616e,
      #f65871,
      #f15075,
      #ec4879
    );  */
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 8px;

    -webkit-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -moz-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -ms-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -o-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
}

.CI-Card .card__front {
    padding: 18px;
    -webkit-transform: rotateY(0);
    -moz-transform: rotateY(0);
}

.CI-Card .card__back {
    padding: 18px 0;
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
}

.CI-Card .card__black-line {
    margin-top: 5px;
    height: 38px;
    background-color: #303030;
}

.CI-Card .card__logo {
    height: 16px;
}

.CI-Card .card__front-logo {
    position: absolute;
    top: 18px;
    right: 18px;
}

.CI-Card .card__square {
    border-radius: 5px;
    height: 30px;
}

.CI-Card .card_numer {
    display: block;
    width: 100%;
    word-spacing: 4px;
    font-size: 20px;
    /* letter-spacing: 2px; */
    color: #fff;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
}

.CI-Card .card__space-75 {
    width: 75%;
    float: left;
}

.CI-Card .card__space-25 {
    width: 25%;
    float: left;
}

.CI-Card .card__label {
    font-size: 10px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.8);
    letter-spacing: 1px;
}

.CI-Card .card__info {
    margin-bottom: 0;
    margin-top: 5px;
    font-size: 16px;
    line-height: 18px;
    color: #fff;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.CI-Card .card__back-content {
    padding: 15px 15px 0;
}

.CI-Card .card__secret--last {
    color: #303030;
    text-align: right;
    margin: 0;
    font-size: 14px;
}

.CI-Card .card__secret {
    padding: 5px 12px;
    background-color: #fff;
    position: relative;
}

.CI-Card .card__secret:before {
    content: "";
    position: absolute;
    top: -3px;
    left: -3px;
    height: calc(100% + 6px);
    width: calc(100% - 42px);
    border-radius: 4px;
    background: repeating-linear-gradient(45deg,
            #ededed,
            #ededed 5px,
            #f9f9f9 5px,
            #f9f9f9 10px);
}

.CI-Card .card__back-logo {
    position: absolute;
    bottom: 15px;
    right: 15px;
}

.CI-Card .card__back-square {
    position: absolute;
    bottom: 15px;
    left: 15px;
}

/* .CI-Card .card:hover .card__front {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
}

.CI-Card .card:hover .card__back {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
} */
.CI-Card .paywave {
    transform: translateZ(30px);
    float: right;
    position: absolute;
    margin: 15px 5px;
    top: 0;
    right: 0;
    filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.5));
    filter: drop-shadow(-6.4px 6.2px 8px rgba(0, 0, 0, 0.6));
    height: 50px;
    width: 50px;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='56' viewBox='0 0 46 56'%3E%3Ctitle%3EContactlessIndicator 000%3C/title%3E%3Cpath fill='none' stroke='%23FFF' stroke-width='6' stroke-linecap='round' d='m35,3a50,50 0 0,1 0,50M24,8.5a39,39 0 0,1 0,39M13.5,13.55a28.2,28.5 0 0,1 0,28.5M3,19a18,17 0 0,1 0,18'/%3E%3C/svg%3E");
}

.CI-Card .chips {
    margin-top: -3px;
    margin-left: -1px;
    height: 45px;
    width: 80px;
    filter: drop-shadow(-6.4px 6.2px 8px rgba(0, 0, 0, 0.6));
    transform: translateZ(25px);
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='50px' x='0px' y='0px' viewBox='0 0 40 40' style='enable-background:new 0 0 40 40;' xml:space='preserve'%3E%3Cg id='surface1'%3E%3Cpath fill='%23F5CE85' d='M8.9,34.5c-6.4,0.1-6.4,0.1-6.4-6.4V11.9c0-3.5-0.1-6.4,3.4-6.4h25.3c6.4,0,6.4,0,6.4,6.4v16.3 c0,6.4,0,6.4-6.4,6.4L8.9,34.5z'/%3E%3Cpath fill='%23967A44' d='M31.1,6C37,6,37,6,37,11.9v16.3c0,5.9,0,5.9-5.9,5.9H8.9c-5.9,0-5.9,0-5.9-6V11.9C3,8.2,2.7,6,6,6H31.1 M31.1,5H9.2C2.3,5,2,5,2,11.9v16.3C2,35,2,35,8.9,35h22.3c6.9-0.1,6.9-0.1,6.9-6.9V11.9C38.1,5,38.1,5,31.1,5z'/%3E%3Cpath fill='%23967A44' d='M29,35c-3.3,0-6-2.7-6-6s2.7-6,6-6h8.5v1H29c-2.8,0-5,2.2-5,5s2.2,5,5,5V35z'/%3E%3Cpath fill='%23967A44' d='M11,35v-1c2.8,0,5-2.2,5-5s-2.2-5-5-5H2.5v-1H11c3.3,0,6,2.7,6,6S14.3,35,11,35z'/%3E%3Cpath fill='%23967A44' d='M37.5,17H27c-2.2,0-4-1.8-4-4s1.8-4,4-4h2.1v1H27c-1.7,0-3,1.3-3,3s1.3,3,3,3h10.5V17z'/%3E%3Cpath fill='%23967A44' d='M28,16h1v7.5h-1V16z'/%3E%3Cpath fill='%23967A44' d='M13,17H2.5v-1H13c1.7,0,3-1.3,3-3s-1.3-3-3-3h-2.1V9H13c2.2,0,4,1.8,4,4S15.2,17,13,17z'/%3E%3Cpath fill='%23967A44' d='M11,16h1v7.5h-1V16z'/%3E%3C/g%3E%3C/svg%3E");
}

.defaultCard>input[type="radio"] {
    width: 2em !important;
    height: 2em !important;
}