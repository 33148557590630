.noteCard .MuiList-padding {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-bottom: -15px !important;
}
.noteHeading {
  border-right: 1px solid transparent !important;
}

.noteTableHeading th {
  font-weight: bolder !important;
  background-color: #ccd1d1;
  text-transform: none !important;
}

.incidentList .MuiTableRow-root.MuiTableRow-hover:hover {
  background-color: lightgrey !important;
}
