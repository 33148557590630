.dateTimeInput {
  color: rgba(0, 0, 0, 0.87);
  cursor: text;
  display: inline-flex;
  position: relative;
  font-size: 1rem;
  box-sizing: border-box;
  align-items: center;
  /* font-family: "Roboto", "Helvetica", "Arial", sans-serif; */
  font-weight: 400;
  line-height: 1.1876em;
  letter-spacing: 0.00938em;
  width: 100%;
  height: 3.1876em;
  padding: 1em;
}

.appointmentTab .nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  background: #005476;
  color: #fff;
  margin: 5px;
}
.appointmentTab .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: none;
}
.appointmentTab .tab-content {
  background: transparent;
  padding: 0px;
  margin: 0em 0em 0em 0.4em;
}

.appointmentTab .react-tabs__tab-list {
  border-bottom: 1px solid #aaa;
  margin: 0 0 0px;
  padding: 0;
}

.react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
  background: #005476;
  margin: 0em 0.5em 0em 0em;
  color: #fff;
  border-radius: 5px 5px 0px 0px;
}

.react-tabs__tab--selected {
  background: #fff !important;
  border-color: #aaa;
  color: black !important;
  border-radius: 5px 5px 0 0;
}

.sponsoredUserList {
  background: #1abc9c;
  color: #fff !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 8px;
}

.editButton {
  float: right;
  font-size: small;
  height: 2em;
  width: 2em;
  background: rgb(19, 98, 105);
  cursor: pointer;
  color: white;
  background-image: -webkit-linear-gradient(bottom, #843b07 0%, #f7b571 100%);
  border: 2px solid #edf0f1;
  border-radius: 50%;
  padding: 2px;
}

.sponsoredUserList .MuiTableCell-head {
  color: #fff;
  font-weight: 500;
  line-height: 1.5rem;
}

.react-datepicker-popper {
  z-index: 2;
}