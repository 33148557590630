.doctorAddress {
  margin-top: -1.5em;
  margin-left: -1em;
}
.doctorcard {
  height: auto;
  background: #fff !important;
  border-radius: 15px !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.07) !important;
}
.appointmentCard {
  height: auto !important;
  padding: 15px;
  background: #fff !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2) !important;
}

.doctorViewCard {
  background: #fff !important;
  border-radius: 15px !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}

.breadcrumbLink {
  color: #4a4a4a;
  background-color: #fff !important;
}
.salesDialogue .MuiDialog-paperScrollPaper {
  width: 75% !important;
  overflow-x: hidden;
}