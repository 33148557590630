

.banner {
  background-color: rgb(240, 240, 251);
}

.banner__content {
  padding: 16px;
  max-width: 700px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner .banner__content .banner-img img {
  width: 100%;
  flex-grow: 0;
  padding: 15px;
  text-align: center;
  height: 50%;
}

.banner__text {
  flex-grow: 1;
  line-height: 1.4;
  font-family: Arial, Helvetica, sans-serif;
  color: black;
}
.banner__viewmore {
  margin: 10px;
  background-color: white;
  color: blueviolet;
  border-radius: 10px;
  border-color: blueviolet;
  width: 160px;
}
.banner__close {
  background: none;
  border: none;
  cursor: pointer;
  color: black;
}


.templateBackground{
  width: 100%;
  background-size: cover;
  -webkit-background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -o-flex;
}