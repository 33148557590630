.importTab .nav-tabs .nav-link {
    margin-bottom: -1px;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
background-color: #fff !important;
color: #495057!important;

    margin: 5px;
}

.importTab .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
color: #fff!important;

    background: #005476!important;
    border-color: #dee2e6 #dee2e6 #fff;
    border-bottom: none;
}

.importTab .tab-content {
    background: transparent;
    padding: 0px;
    margin: 0em 0em 0em 0.4em;
}