

.consolidatedDayView .MuiTableCell-head {
    // border: 1px solid #000!important
    border-right: 1px solid!important;
        border-bottom: 1px solid!important;
}

// .consolidatedDayView .MuiTableCell-root {
//     border: 1px solid gray !important
// }
.consolidatedDayView .MuiTableCell-stickyHeader {

    background-color: #B2BABB !important;
}
.currentDayCol{
    text-align: center;
        color: #000;

}
.selectDateBtn{
        background: #ef6603;
            color: white;
            text-transform: capitalize;
            cursor: pointer;
            float: right;
            margin: 0.2em 0.5em 0.3em 0em;
}
#classicViewBtn{
background: #C0392B!important;
            color: white;
            text-transform:capitalize;
            cursor: pointer;
           float: right;
            font-size: medium;
            // margin: 0.2em 0.5em 0.3em 0em;
}

#filterBtn{
    background: rgb(239, 102, 3);
        color: rgb(255, 255, 255);
         margin-top: 7px;
        text-transform: capitalize;
        cursor: pointer;
        height: 42px;
        width: 30%;
        font-size: medium;
}
#resetBtn{
    background: #000;
        color: rgb(255, 255, 255);
       margin-top: 7px;
        text-transform: capitalize;
        cursor: pointer;
        height: 42px;
        width: 30%;
        font-size: medium;
}
#printBtn{
        background: #3498DB;
        color: rgb(255, 255, 255);
        text-transform: capitalize;
        cursor: pointer;
        min-height: 32px;
        // width: 25%;
float: right;
        font-size: medium;
        margin: 0em 0.5em 0.3em 0em;
    }

        #excelBtn{
                background: rgb(90, 196, 137);
                color: rgb(255, 255, 255);
                text-transform: capitalize;
                cursor: pointer;
                min-height: 32px;
margin: 0em 0.5em 0.3em 0em;
                float: right;
                font-size: medium;
            }
#nextWeekBtn{
        // border-radius: 8%;
        //     background: rgb(0, 0, 0);
        //     color: white;
        //     font-size: 2px;
        //     width: 47px;
        //     min-height: 38px;
        //     padding-left: 0.9em;
        //     float: left;
        //     cursor: pointer;
            border-radius: 8%;
                background: black;
                color: white;
                font-size: 2px;
                width: 30px;
                    min-height: 32px;
                padding-left: 3em;
                cursor: pointer;
}

#previousBtn{
    border-radius: 8%;
        background: black;
        color: white;
        font-size: 2px;
        width: 30px;
            min-height: 32px;
        padding-left: 3em;
        float: right;
        cursor: pointer;
    }
::-webkit-scrollbar {

        /* <-- This sets the thickness of the VERTICAL scrollbar */
        height: 7px;
        /* <-- This sets the thickness of the HORIZONTAL scrollbar */
    }

        .transform-component-module_wrapper__1_Fgj {
            position: relative;
        width: 100% !important;
        }

         .transform-component-module_content__2jYgh  {
                    /* display: flex; */
                    /* flex-wrap: wrap; */
                    width:100% !important;
                    /* width: fit-content; */
                    height: none !important;
                    /* height: fit-content; */
                    margin: 0;
                    padding: 0;
                    transform-origin: 0% 0%;
                }

  #weekCell.MuiTableCell-root {
    padding: 0px !important;
  }
#weekCell .MuiTableBody-root {
    display: none !important;
}

#weekCell.MuiTableCell-root {
 border: none !important;
            padding: 0px !important;
        }
#weekBody .MuiTableHead-root {
    display: none !important;
}
// #weekTableHead .MuiTableCell-root {
// border:1px solid #000 !important
// }
// .weekViewTable .TableRow .TableCell{
//         border: 1px solid #000 !important
//     }
.weekViewTable .MuiTableCell-root {
    // border-bottom: none !important;
    // border-right: none !important;
    border: 1px solid;
}
.weekViewTable .MuiTableCell-head {
    // border: 1px solid #000!important
    border-right: none !important;
    border-bottom: none !important;
}