.plan-inner_free_plan_card_plan_card_bottom .entry-title {
  background: #75ddd9;
}

.plan-inner_free_plan_card_plan_card_bottom .entry-title > h3 {
  background: #44cbc6;
}

.plan-inner_free_plan_card_plan_card_bottom .price {
  background: #44cbc6;
}

.profileTab .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  background: #005476 !important;
  color: #fff !important;
  margin: 5px;
}

.profileTab .nav-tabs .nav-item {
  margin-bottom: 0;
  color: #000;
  background: #fff;
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  margin: 5px;
}

.profileTab .tab-content {
  background: transparent;
  padding: 0px;
}

.profileTab .nav-tabs {
  border-bottom: none;
}

.subscriptionModal .MuiDialogContent-root:first-child {
  padding-top: 5px;
}
