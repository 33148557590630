.plan_detail_header {
  background: #044563 !important;
  color: #fff !important;
}

.planDetailsTitle h2 {
  font-size: 17px;
  font-weight: 500;
  padding: 0;
  line-height: 1px;
  margin: 0 0 5px 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #aaaaaa;
  font-family: 'Poppins', sans-serif;
}

.packageSelection {
  color: #ef6603;
  font-weight: bold;
}
