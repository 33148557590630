.addressRow {
  // margin: 0;
  // padding: 0;
  // margin-bottom: 0.5em !important;
  margin-left: 0.5em !important;
  align-items: center;
  text-transform: capitalize;
}

.editAddressRow {
  // margin: 0;
  // padding: 0.5em;
  // margin-bottom: 0.5em !important;
  // margin-left: 0.5em !important;
  align-items: center;
}
.editAddressCol {
  margin-bottom: 0.5em;
  // margin-left: 0.5em !important;
}
.addressHeading {
  background: transparent;

  font-weight: 700;
}
