.formCard {
  height: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.cardHeaderTitle {
  font-weight: 600;
  color: #0099f1;
  margin-left: 0.3em;
}

.cardHeader {
  padding: 0.3em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.sponsorDetailColumn {
  position: relative;
  padding: 5px;
}
.ptsIcon {
  font-size: large;
  color: #000;
}
.sponsorDetailFooter {
  text-align: center;
  width: 100%;
  margin-top: 2em;
  padding: 0em 0em 1em;
}
