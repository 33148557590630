// .banner {
//     background-color: rgb(240, 240, 251);
// }

// .banner__content {
//     padding: 16px;
//     max-width: 700px;
//     margin: 0 auto;
//     display: flex;
//     justify-content: center;
//     align-items: center;
// }

// .banner .banner__content .banner-img img {
//     width: 100%;
//     flex-grow: 0;
//     padding: 15px;
//     text-align: center;
//     height: 50%;
// }

// .banner__text {
//     flex-grow: 1;
//     line-height: 1.4;
//     font-family: Arial, Helvetica, sans-serif;
//     color: black;
// }

// .banner__viewmore {
//     margin: 10px;
//     background-color: white;
//     color: blueviolet;
//     border-radius: 10px;
//     border-color: blueviolet;
//     width: 160px;
// }

// .banner__close {
//     background: none;
//     border: none;
//     cursor: pointer;
//     color: black;
// }


// .templateBackground {
//     width: 100%;
//     background-size: cover;
//     -webkit-background-size: cover;
//     background-position: center center;
//     background-repeat: no-repeat;
//     justify-content: center;
//     align-items: center;
//     flex-flow: column;
//     display: -webkit-flex;
//     display: -moz-flex;
//     display: -ms-flexbox;
//     display: -o-flex;
// }
#Ad01-h1 {
    font-size: 30px;
    font-weight: 700;
    margin-top: -12px;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: #000;
    text-align: center;
}

#Ad01-h2 {
    font-size: 15px;
    font-weight: 500;
    margin-top: -20px;
    text-transform: capitalize;
    letter-spacing: 1px;
    color: #000;
    text-align: center;
}

#Ad01-header a {

    text-decoration: none;
    color: #fff;
    //   background: #313030;
    //   padding: 7px 15px;
    //   margin-top: -10px;
    //   border-radius: 5px;
    font-size: 13px;
}

#Ad01-p {
    font-size: 12px;
    margin-top: -5px;
    padding: 0 15%;
    text-align: center;
    color: #000;
}

#Ad01-header {
    // background: url("./bgAd01.jpg");
    padding: 1em;
    min-height: 180px;
    width: 100%;
    background-size: cover;
    -webkit-background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -o-flex;
}


.textRow .MuiInputBase-input {
    font: inherit;
    color: currentColor;
    width: 100%;
    border: 0;
    height: 1.5em;
    margin: 0;
    display: block;
    padding: 6px 0 7px;
    min-width: 0;
    background: none;
    box-sizing: content-box;
    animation-name: mui-auto-fill-cancel;
    letter-spacing: inherit;
    animation-duration: 10ms;
    -webkit-tap-highlight-color: transparent;
}

.textRow .MuiInputLabel-outlined {
    z-index: 1;
    transform: translate(14px, 20px) scale(1);
    pointer-events: none;
    margin-top: -0.5em;
}

#templateBackground {
    width: 100%;
    background-size: cover;
    -webkit-background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -o-flex;
}