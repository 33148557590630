
.banner-container {
  // display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
  display: block;

        width: 100%;

}

.banner-container .banner {
  // background: #CD5C5C;
  border-radius: 5px;
  margin: 10px;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  box-shadow: 0 5px 10px rgb(228, 222, 222);
  overflow: hidden;
  height: 120px;
  width: 100%;
  background-size: cover;
  -webkit-background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  justify-content: center;

}

.banner-container .banner .shoe {
  flex: 0 1 auto;
  padding: 15px;
  text-align: center;
}

.banner-container .banner .shoe img {
  width: 100%;
}
.banner-container .banner .content {
  flex: 0 1 auto;
  text-align: center;
  padding: 5px;

}

.banner-container .banner .content h4 {
  color: white;
  font-size: 20px;
  font-weight: bolder;
}
.banner-container .banner .content h3 {
  color: #fff;
  font-size: 15px;
}
.banner-container .banner .content p {
  // color: #000080	;
  font-size: 12px;
  margin-bottom: 0em !important;
  text-transform: none;
  // padding: 10px 0;
}
.banner-container .banner .content .btn {
  display: block;
  height: 40px;
  width: 150px;
  line-height: 20px;
  background: #fff;
  color: red;
  margin: 5px auto;
  text-decoration: none;
}

/* ---------*/
.banner-container .banner .women {
  position: relative;
  bottom: -3px;
  padding: 10px;
  flex: 0 1 auto;
}

.banner-container .banner .women img {
  width: 100%;
}

/*--- media query apply here */
@media All and (min-width: 768px) /*screen and (min-width: 480px) */ {
  .banner-container .banner {
    display: flex;

    flex: 0 1 auto;
  }
}

@media All and (max-width: 768px) /*screen and (min-width: 480px) */ {
  .banner-container .banner {
    display: flex;
    flex-wrap: wrap;
    flex: 0 1 auto;
  }
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  // float:left;
}


.fileUploadBtn {
  border: 2px solid gray;
  color: gray;
  background-color: white;
  padding: 3px 5px;
  border-radius: 8px;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.templateBackground {
  width: 100%;
  background-size: cover;
  -webkit-background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -o-flex;
}