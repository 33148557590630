.transportationAppointmentList .MuiPaper-elevation2 {
  box-shadow: 10px 10px 10px #fff;
}

.transportationView .card {
  background: #f4ecf7 !important;
}

@media print {
  .table.table thead {
      background: #1ABC9C !important;
    }
}
