.referralBox{
border: 1px solid lightgray;
    padding: 1.5em;
    text-align: center;
    margin: 0.8em;
    width: 30%;
}
.CopyLink{
color: #007DD3;
    font-size: 15px;
    width: 12%;
    text-align: right;
    cursor: pointer;
    margin: auto;
}
.iconColumn{
    margin: inherit;
        padding: 2.5em;
        text-align: right;
}

.socialMediaIcons .ewcqji svg {
    width: 2em;
    height: 2em;
}