.billing_cycle .MuiSwitch-thumb {
  width: 20px;
  height: 18px;
  /* margin-top: 0.1em; */
  margin: 0.3em;

  border-radius: 50%;
  background-color: currentColor;
}

.billing_plan-inner {
  background: #fff;
  margin: 0 auto;
  min-width: 280px;
  max-width: 100%;
  position: relative;
  min-height: 186px;
  border-radius: 10px;

  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2) !important;
  /* border-radius: 9px; */
}
