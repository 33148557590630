.treeItemsData .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label {
  background-color: #abebc6;
  padding: 1em;
  border-radius: 5px;
  border: 1px solid lightgray;
}

.treeItemsChildData .MuiTreeItem-label {
  width: 100%;
  position: relative;
  padding-left: 4px;
  padding: 1em;
  border-radius: 5px;
  font-size: 14px;
}
.labelData td {
  padding-right: 10px;
}

.treeItemsChildData .MuiTreeItem-group {
  margin: 0;
  padding: 0;
  margin-left: 0px;
}

.treeItemsChildData .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover,
.MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label {
  background-color: #abebc6;
}

.treeItemsChildData .MuiTreeItem-root:focus > .MuiTreeItem-content .MuiTreeItem-label {
  background-color: #abebc6;
}

.tableHeadRow.MuiTableCell-head {
  color: #fff;
  font-weight: bold;
  background-color: #1abc9c;
}
