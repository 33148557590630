/**
* Template Name: Selecao - v2.3.1
* Template URL: https://bootstrapmade.com/selecao-bootstrap-template/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
/* body {
  font-family: 'Open Sans', sans-serif;
  color: #444444;
} */

a {
  color: #ef6603;
}

a:hover {
  color: #fc8129;
  text-decoration: none;
}

/* h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: 'Raleway', sans-serif;
    } */

/*--------------------------------------------------------------
    # Back to top button
    --------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  display: none;
  right: 15px;
  bottom: 15px;
  z-index: 99999;
}

.back-to-top i {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background: #ef6603;
  color: #fff;
  transition: all 0.4s;
}

.back-to-top i:hover {
  background: #fc7c1f;
  color: #fff;
}

/*--------------------------------------------------------------
    # Disable AOS delay on mobile
    --------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
    # Header
    --------------------------------------------------------------*/
#header {
  height: 70px;
  transition: all 0.5s;
  z-index: 997;
  transition: all 0.5s;
  background: #074246;
}

/* #header.header-transparent {
      background: transparent;
    } */

#header.header-scrolled {
  background: #074246;
}

#header .logo h1 {
  font-size: 28px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 1px;
}

#header .logo h1 a,
#header .logo h1 a:hover {
  color: #fff;
  text-decoration: none;
}

#header .logo img {
  padding: 0;
  margin: 0;
  max-height: 40px;
}

/*--------------------------------------------------------------
    # Navigation Menu
    --------------------------------------------------------------*/
/* Desktop Navigation */
.nav-menu,
.nav-menu * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-menu > ul > li {
  position: relative;
  white-space: nowrap;
  float: left;
}

.nav-menu a {
  display: block;
  position: relative;
  color: #fff;
  padding: 5px 15px 7px 15px;
  margin-left: 5px;
  transition: 0.3s;
  font-size: 14px;
  border-radius: 50px;
  font-family: 'Open Sans', sans-serif;
}

.nav-menu a:hover,
.nav-menu .active > a,
.nav-menu li:hover > a {
  background: #ef6603;
  text-decoration: none;
}

.nav-menu .drop-down ul {
  display: block;
  position: absolute;
  left: 0;
  top: calc(100% - 30px);
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  padding: 10px 0;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: ease all 0.3s;
  border-radius: 15px;
  margin-top: 5px;
}

.nav-menu .drop-down:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.nav-menu .drop-down li {
  min-width: 180px;
  position: relative;
}

.nav-menu .drop-down ul a {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  text-transform: none;
  color: #2a2c39;
}

.nav-menu .drop-down ul a:hover,
.nav-menu .drop-down ul .active > a,
.nav-menu .drop-down ul li:hover > a {
  color: #ef6603;
  background: none;
}

.nav-menu .drop-down > a:after {
  content: '\ea99';
  font-family: IcoFont;
  padding-left: 5px;
}

.nav-menu .drop-down .drop-down ul {
  top: 0;
  left: calc(100% - 30px);
}

.nav-menu .drop-down .drop-down:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
}

.nav-menu .drop-down .drop-down > a {
  padding-right: 35px;
}

.nav-menu .drop-down .drop-down > a:after {
  content: '\eaa0';
  font-family: IcoFont;
  position: absolute;
  right: 15px;
}

@media (max-width: 1366px) {
  .nav-menu .drop-down .drop-down ul {
    left: -90%;
  }
  .nav-menu .drop-down .drop-down:hover > ul {
    left: -100%;
  }
  .nav-menu .drop-down .drop-down > a:after {
    content: '\ea9d';
  }
}

/* Mobile Navigation */
.mobile-nav-toggle {
  position: fixed;
  right: 15px;
  top: 15px;
  z-index: 9998;
  border: 0;
  background: none;
  font-size: 24px;
  transition: all 0.4s;
  outline: none !important;
  line-height: 1;
  cursor: pointer;
  text-align: right;
}

.mobile-nav-toggle i {
  color: #fff;
}

.mobile-nav {
  position: fixed;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  z-index: 9999;
  overflow-y: auto;
  background: #fff;
  transition: ease-in-out 0.2s;
  opacity: 0;
  visibility: hidden;
  border-radius: 10px;
  padding: 10px 0;
}

.mobile-nav * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.mobile-nav a {
  display: block;
  position: relative;
  color: #2a2c39;
  padding: 10px 20px;
  font-weight: 500;
  outline: none;
}

.mobile-nav a:hover,
.mobile-nav .active > a,
.mobile-nav li:hover > a {
  color: #ef6603;
  text-decoration: none;
}

.mobile-nav .drop-down > a:after {
  content: '\ea99';
  font-family: IcoFont;
  padding-left: 10px;
  position: absolute;
  right: 15px;
}

.mobile-nav .active.drop-down > a:after {
  content: '\eaa1';
}

.mobile-nav .drop-down > a {
  padding-right: 35px;
}

.mobile-nav .drop-down ul {
  display: none;
  overflow: hidden;
}

.mobile-nav .drop-down li {
  padding-left: 20px;
}

.mobile-nav-overly {
  width: 100%;
  height: 100%;
  z-index: 9997;
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(20, 21, 28, 0.6);
  overflow: hidden;
  display: none;
  transition: ease-in-out 0.2s;
}

.mobile-nav-active {
  overflow: hidden;
}

.mobile-nav-active .mobile-nav {
  opacity: 1;
  visibility: visible;
}

.mobile-nav-active .mobile-nav-toggle i {
  color: #fff;
}

/*--------------------------------------------------------------
    # Hero Section
    --------------------------------------------------------------*/

/*--------------------------------------------------------------
    # Sections General
    --------------------------------------------------------------*/
/* section {
      padding: 60px 0;
      overflow: hidden;
    } */

.contactSection-title {
  padding-bottom: 40px;
}

.contactSection-title h2 {
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  line-height: 1px;
  margin: 0 0 5px 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #aaaaaa;
  font-family: 'Poppins', sans-serif;
}

.contactSection-title h2::after {
  content: '';
  width: 120px;
  height: 1px;
  display: inline-block;
  background: #fd9042;
  margin: 4px 10px;
}

.contactSection-title p {
  margin: 0;
  margin: 0;
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif;
  color: #2a2c39;
}

/*--------------------------------------------------------------
    # Contact
    --------------------------------------------------------------*/
.contact .info {
  width: 100%;
  background: #fff;
}

.contact .info i {
  font-size: 20px;
  color: #ef6603;
  float: left;
  width: 44px;
  height: 44px;
  background: #ffecde;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #2a2c39;
}

.contact .info p {
  padding: 0 0 0 60px;
  margin-bottom: 0;
  font-size: 14px;
  color: #555974;
}

.contact .info .email,
.contact .info .phone {
  margin-top: 40px;
}

.contact .info .email:hover i,
.contact .info .address:hover i,
.contact .info .phone:hover i {
  background: #ef6603;
  color: #fff;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
    # Breadcrumbs
    --------------------------------------------------------------*/

/*--------------------------------------------------------------
    # Portfolio Details
    --------------------------------------------------------------*/

/*--------------------------------------------------------------
    # Footer
    --------------------------------------------------------------*/
#contactUsFooter {
  background: #14151c;
  color: #fff;
  font-size: 14px;
  text-align: center;
  padding: 30px 0;
}

#contactUsFooter h3 {
  font-size: 36px;
  font-weight: 700;
  color: #fff;
  position: relative;
  font-family: 'Poppins', sans-serif;
  padding: 0;
  margin: 0 0 15px 0;
}

#contactUsFooter p {
  font-size: 15;
  font-style: italic;
  padding: 0;
  margin: 0 0 40px 0;
}

#contactUsFooter .social-links {
  margin: 0 0 40px 0;
}

#contactUsFooter .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #ef6603;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#contactUsFooter .social-links a:hover {
  background: #bd5102;
  color: #fff;
  text-decoration: none;
}

#contactUsFooter .copyright {
  margin: 0 0 5px 0;
}

#contactUsFooter .credits {
  font-size: 13px;
}

.sponsorship_benefitsDetails {
  font-size: 16px;
  color: #3498db;
  font-weight: 500;
  font-style: italic;
}
.sponsorship_benefit li {
  font-size: 16px;
}
