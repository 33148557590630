.RegistrationTitle {
  text-align: center;
  // font-weight: bold;
  color: #fff !important;
  margin-top: 0.5em;
  padding: 0.2em;
}

.table {
  color: #000 !important;
  // font-weight: bold !important;
  background: #e0f3e0;
}

.tableContents {
  font-weight: bold !important;
  text-align: left;
  // font-size: 13px;
}

table.table tbody td {
  border: none !important;
}

table.table tbody tr {
  border: 1px solid lightgray;
}
