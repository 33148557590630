.landingPage {
  height: 100vh;
  overflow-y: scroll !important;
}

.nav-menu a:hover,
.nav-menu .active > a,
.nav-menu li:hover > a {
  background: #ef6603;
  text-decoration: none;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  display: none;
  right: 15px;
  bottom: 15px;
  z-index: 99999;
}

.back-to-top i {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background: #ef6603;
  color: #fff;
  transition: all 0.4s;
}

.back-to-top i:hover {
  background: #fc7c1f;
  color: #fff;
}

/*--------------------------------------------------------------
  # Disable AOS delay on mobile
  --------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }

  #fccSlider .react-slideshow-container {
    margin: 0px !important;
    padding-top: 10em !important;
  }
}

/*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/
#header {
  height: 70px;
  transition: all 0.5s;
  z-index: 997;
  transition: all 0.5s;
  background: rgba(42, 44, 57, 0.9);
}

#header.header-transparent {
  background: #008181;
}

#header.header-scrolled {
  background: #074246;
}

#header .logo h1 {
  font-size: 28px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 1px;
}

#header .logo h1 a,
#header .logo h1 a:hover {
  color: #fff;
  text-decoration: none;
}

#header .logo img {
  padding: 0;
  margin: 0;
  max-height: 40px;
}

/*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
/* Desktop Navigation */
.nav-menu,
.nav-menu * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-menu > ul > li {
  position: relative;
  white-space: nowrap;
  float: left;
}

.nav-menu a {
  display: block;
  position: relative;
  color: #fff;
  padding: 5px 15px 7px 15px;
  margin-left: 5px;
  transition: 0.3s;
  font-size: 14px;
  border-radius: 50px;
  font-family: 'Open Sans', sans-serif;
}

.nav-menu a:hover,
.nav-menu .active > a,
.nav-menu li:hover > a {
  background: #ef6603;
  text-decoration: none;
}

.nav-menu .drop-down ul {
  display: block;
  position: absolute;
  left: 0;
  top: calc(100% - 30px);
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  padding: 10px 0;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: ease all 0.3s;
  border-radius: 15px;
  margin-top: 5px;
}

.nav-menu .drop-down:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.nav-menu .drop-down li {
  min-width: 180px;
  position: relative;
}

.nav-menu .drop-down ul a {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  text-transform: none;
  color: #2a2c39;
}

.nav-menu .drop-down ul a:hover,
.nav-menu .drop-down ul .active > a,
.nav-menu .drop-down ul li:hover > a {
  color: #ef6603;
  background: none;
}

.nav-menu .drop-down > a:after {
  content: '\ea99';
  font-family: IcoFont;
  padding-left: 5px;
}

.nav-menu .drop-down .drop-down ul {
  top: 0;
  left: calc(100% - 30px);
}

.nav-menu .drop-down .drop-down:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
}

.nav-menu .drop-down .drop-down > a {
  padding-right: 35px;
}

.nav-menu .drop-down .drop-down > a:after {
  content: '\eaa0';
  font-family: IcoFont;
  position: absolute;
  right: 15px;
}

@media (max-width: 1366px) {
  .nav-menu .drop-down .drop-down ul {
    left: -90%;
  }
  .nav-menu .drop-down .drop-down:hover > ul {
    left: -100%;
  }
  .nav-menu .drop-down .drop-down > a:after {
    content: '\ea9d';
  }
}

/* Mobile Navigation */
.mobile-nav-toggle {
  position: fixed;
  right: 15px;
  top: 15px;
  z-index: 9998;
  border: 0;
  background: none;
  font-size: 24px;
  transition: all 0.4s;
  outline: none !important;
  line-height: 1;
  cursor: pointer;
  text-align: right;
}

.mobile-nav-toggle i {
  color: #fff;
  margin-right: -0.5em;
}

.mobile-nav {
  position: fixed;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  z-index: 9999;
  overflow-y: auto;
  background: #fff;
  transition: ease-in-out 0.2s;
  opacity: 0;
  visibility: hidden;
  border-radius: 10px;
  padding: 10px 0;
}

.mobile-nav * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.mobile-nav a {
  display: block;
  position: relative;
  color: #2a2c39;
  padding: 10px 20px;
  font-weight: 500;
  outline: none;
}

.mobile-nav a:hover,
.mobile-nav .active > a,
.mobile-nav li:hover > a {
  color: #ef6603;
  text-decoration: none;
}

.mobile-nav .drop-down > a:after {
  content: '\ea99';
  font-family: IcoFont;
  padding-left: 10px;
  position: absolute;
  right: 15px;
}

.mobile-nav .active.drop-down > a:after {
  content: '\eaa1';
}

.mobile-nav .drop-down > a {
  padding-right: 35px;
}

.mobile-nav .drop-down ul {
  display: none;
  overflow: hidden;
}

.mobile-nav .drop-down li {
  padding-left: 20px;
}

.mobile-nav-overly {
  width: 100%;
  height: 100%;
  z-index: 9997;
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(20, 21, 28, 0.6);
  overflow: hidden;
  display: none;
  transition: ease-in-out 0.2s;
}

.mobile-nav-active {
  overflow: hidden;
}

.mobile-nav-active .mobile-nav {
  opacity: 1;
  visibility: visible;
}

.mobile-nav-active .mobile-nav-toggle i {
  color: #fff;
}

/*--------------------------------------------------------------
  # Hero Section
  --------------------------------------------------------------*/
#hero {
  min-height: 500px !important;
  width: 100%;
  overflow: hidden;
  position: relative;
  background-image: url('./assets/img/background.png');
  /* background: linear-gradient(0deg, #066f72 0%, #04373e 100%); */
  padding: 0;
  background-repeat: no-repeat;
}

#hero .carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  position: relative;
  height: 70vh;
  padding-top: 60px;
}

#hero h2 {
  color: #fff;
  margin-bottom: 30px;
  font-size: 48px;
  font-weight: 700;
}

#hero p {
  width: 80%;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  margin: 0 auto 30px auto;
  color: #fff;
}

#hero .carousel-control-prev,
#hero .carousel-control-next {
  width: 10%;
}

#hero .carousel-control-next-icon,
#hero .carousel-control-prev-icon {
  background: none;
  font-size: 48px;
  line-height: 1;
  width: auto;
  height: auto;
}

#hero .btn-get-started {
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 7px;
  border-radius: 50px;
  transition: 0.5s;
  line-height: 1;
  margin: 10px;
  color: #fff;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  border: 2px solid #ef6603;
}

#hero .btn-get-started:hover {
  background: #ef6603;
  color: #fff;
  text-decoration: none;
}

@media (min-width: 1024px) {
  #hero p {
    width: 60%;
  }
  #hero .carousel-control-prev,
  #hero .carousel-control-next {
    width: 5%;
  }
}

@media (max-width: 768px) {
  #hero .carousel-container {
    height: 90vh;
  }
  #hero h2 {
    font-size: 28px;
  }
}

.hero-waves {
  display: block;
  width: 100%;
  height: 60px;
  position: relative;
}

/* .wave1 use {
    -webkit-animation: move-forever1 10s linear infinite;
    animation: move-forever1 10s linear infinite;
    -webkit-animation-delay: -2s;
    animation-delay: -2s;
  }

  .wave2 use {
    -webkit-animation: move-forever2 8s linear infinite;
    animation: move-forever2 8s linear infinite;
    -webkit-animation-delay: -2s;
    animation-delay: -2s;
  }

  .wave3 use {
    -webkit-animation: move-forever3 6s linear infinite;
    animation: move-forever3 6s linear infinite;
    -webkit-animation-delay: -2s;
    animation-delay: -2s;
  } */

@-webkit-keyframes move-forever1 {
  0% {
    transform: translate(85px, 0%);
  }
  100% {
    transform: translate(-90px, 0%);
  }
}

@keyframes move-forever1 {
  0% {
    transform: translate(85px, 0%);
  }
  100% {
    transform: translate(-90px, 0%);
  }
}

@-webkit-keyframes move-forever2 {
  0% {
    transform: translate(-90px, 0%);
  }
  100% {
    transform: translate(85px, 0%);
  }
}

@keyframes move-forever2 {
  0% {
    transform: translate(-90px, 0%);
  }
  100% {
    transform: translate(85px, 0%);
  }
}

@-webkit-keyframes move-forever3 {
  0% {
    transform: translate(-90px, 0%);
  }
  100% {
    transform: translate(85px, 0%);
  }
}

@keyframes move-forever3 {
  0% {
    transform: translate(-90px, 0%);
  }
  100% {
    transform: translate(85px, 0%);
  }
}

/*--------------------------------------------------------------
  # Sections General
  --------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
}

.section-title {
  padding-bottom: 15px;
}

.section-title h2 {
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  line-height: 1px;
  margin: 0 0 5px 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #aaaaaa;
  font-family: 'Poppins', sans-serif;
}

.section-title h2::after {
  content: '';
  width: 120px;
  height: 1px;
  display: inline-block;
  background: #fd9042;
  margin: 4px 10px;
}

.section-title p {
  margin: 0;
  margin: 0;
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif;
  color: #2a2c39;
}

/*--------------------------------------------------------------
  # About
  --------------------------------------------------------------*/
.about {
  padding-top: 80px;
}

.about .content h3 {
  font-weight: 600;
  font-size: 26px;
}

.about .content ul {
  list-style: none;
  padding: 0;
}

.about .content ul li {
  padding-left: 28px;
  position: relative;
}

.about .content ul li + li {
  margin-top: 10px;
}

.about .content ul i {
  position: absolute;
  left: 0;
  top: 2px;
  font-size: 20px;
  color: #ef6603;
  line-height: 1;
}

.about .content p:last-child {
  margin-bottom: 0;
}

.about .content .btn-learn-more {
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 32px;
  border-radius: 5px;
  transition: 0.3s;
  line-height: 1;
  color: #ef6603;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  margin-top: 6px;
  border: 2px solid #ef6603;
}

.about .content .btn-learn-more:hover {
  background: #ef6603;
  color: #fff;
  text-decoration: none;
}

/*--------------------------------------------------------------
  # Features
  --------------------------------------------------------------*/
.features .nav-tabs {
  border: 0;
}

.features .nav-link {
  border: 1px solid #d4d6df;
  /* padding: 15px; */
  transition: 0.3s;
  color: #2a2c39;
  border-radius: 0;
  /* display: flex; */
  align-items: center;
  justify-content: center;
}

.features .nav-link i {
  padding-right: 15px;
  font-size: 48px;
}

.features .nav-link h4 {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.features .nav-link:hover {
  color: #ef6603;
}

.features .nav-link.active {
  background: #ef6603;
  color: #fff;
  border-color: #ef6603;
}

@media (max-width: 768px) {
  .features .nav-link i {
    padding: 0;
    line-height: 1;
    font-size: 36px;
  }
}

@media (max-width: 575px) {
  .features .nav-link {
    padding: 15px;
  }
  .features .nav-link i {
    font-size: 24px;
  }
}

.features .tab-content {
  margin-top: 30px;
}

.features .tab-pane h3 {
  font-weight: 600;
  font-size: 26px;
}

.features .tab-pane ul {
  list-style: none;
  padding: 0;
}

.features .tab-pane ul li {
  padding-bottom: 10px;
}

.features .tab-pane ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #ef6603;
}

.features .tab-pane p:last-child {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
  # Cta
  --------------------------------------------------------------*/
.cta {
  background: #2a2c39;
  padding: 80px 0;
}

.cta h3 {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
}

.cta p {
  color: #fff;
}

.cta .cta-btn {
  font-family: 'Raleway', sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 30px;
  border-radius: 50px;
  transition: 0.5s;
  margin: 10px;
  color: #fff;
  background: #ef6603;
}

.cta .cta-btn:hover {
  background: #fff;
  color: #ef6603;
}

@media (max-width: 1024px) {
  .cta {
    background-attachment: scroll;
  }
}

@media (min-width: 769px) {
  .cta .cta-btn-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

/*--------------------------------------------------------------
  # Services
  --------------------------------------------------------------*/
.services {
  font-family: sans-serif, sans-serif !important ;
}
.services .icon-box {
  /* padding: 30px; */
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  background: #fff;
  /* box-shadow: 0 2px 29px 0 rgba(68, 88, 144, 0.12); */
  transition: all 0.4s ease-in-out;
  width: 100%;
  height: 100%;
}

.services .icon-box:hover {
  transform: translateY(-10px);
  /* box-shadow: 0 2px 35px 0 rgba(68, 88, 144, 0.2); */
}

.services .icon {
  position: absolute;
  left: -20px;
  top: calc(50% - 30px);
}

.services .icon i {
  font-size: 64px;
  line-height: 1;
  transition: 0.5s;
}

.services .title {
  margin-left: 40px;
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 18px;
}

.services .title a {
  color: #2a2c39;
  transition: ease-in-out 0.3s;
  font-family: sans-serif, sans-serif;
}

.services .title a:hover {
  color: #ef6603;
}

.services .description {
  font-size: 14px;
  margin-left: 40px;
  line-height: 24px;
  margin-bottom: 0;
}

.sectionList {
  font-family: sans-serif, sans-serif;
  line-height: 26px;
  font-size: 14px;
}

#serviceCardTitle {
  color: #fff;
  padding: 6px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0px;
  background: #82e0aa;
}

#homeFooter {
  background: #14151c;
  color: #fff;
  font-size: 14px;
  text-align: center;
  padding: 30px 0;
}

#homeFooter h3 {
  font-size: 36px;
  font-weight: 700;
  color: #fff;
  position: relative;
  font-family: 'Poppins', sans-serif;
  padding: 0;
  margin: 0 0 15px 0;
}

#homeFooter p {
  font-size: 15;
  font-style: italic;
  padding: 0;
  margin: 0 0 40px 0;
}

#homeFooter .social-links {
  margin: 0 0 40px 0;
}

#homeFooter .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #ef6603;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#homeFooter .social-links a:hover {
  background: #bd5102;
  color: #fff;
  text-decoration: none;
}

#homeFooter .copyright {
  margin: 0 0 5px 0;
}

#homeFooter .credits {
  font-size: 13px;
}

.contactIfo_icon {
  font-size: 20px;
  color: #ef6603;
  float: left;
  width: 33px;
  height: 34px;
  // background: #ffecde;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

/*--------------------------------------------------------------
# F.A.Q
--------------------------------------------------------------*/
.faq {
  padding: 60px 0;
  font-family: sans-serif, sans-serif !important;
}

.faq .faq-list {
  padding: 0;
  list-style: none;
}

.faq .faq-list li {
  padding: 0 0 20px 25px;
}

.faq .faq-list a {
  display: block;
  position: relative;
  font-family: #ef6603;
  font-size: 18px;
  font-weight: 500;
}

.faq .faq-list i {
  font-size: 18px;
  position: absolute;
  left: -25px;
  top: 6px;
}

.faq .faq-list p {
  margin-bottom: 20px;
  font-size: 15px;
}

.faq .faq-list a.collapse {
  color: #ef6603;
}

.faq .faq-list a.collapsed {
  color: #343a40;
}

.faq .faq-list a.collapsed:hover {
  color: #ef6603;
}

.faq .faq-list a.collapsed i::before {
  content: '\eab2' !important;
}

.fccFaqHeading .accordion__button {
  background-color: #074246 !important;
  color: #fff !important;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  /* font-size: 15px; */
  transition: 0.4s;
  font-weight: bold;
  margin-bottom: 20px;
}

#fccFaqHeading:after {
  // content: '\002B';
  color: #fff;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}

#fccFaqHeading.active::after {
  // content: '\2212';
}

.accordion__panel {
  padding: 0 20px !important;
  background-color: white;
}

.businessPromotion {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
  border-radius: 5px;
}

.businessPromotionCheckBox {
  width: 20px; /*Desired width*/
  height: 20px; /*Desired height*/
}
.modal-footer {
  border-top: none;
}

.virRepHead {
  /* margin:20px 0px; */
  padding-bottom: 20px;
  text-align: center;
  font-family: Verdana, Arial, Helvetica, sans-serif;
  color: #fff;
}
.virRepHead h2 {
  /* margin-top:20px; */
  text-align: center;
  font-family: Verdana, Arial, Helvetica, sans-serif;
}
.grid {
  list-style: none;
  margin-left: 25px;
}
.gc {
  box-sizing: border-box;
  display: inline-block;
  margin-right: -0.25em;
  min-height: 1px;
  padding: 0px 20px;
  vertical-align: top;
}
.gc--1-of-3 {
  width: 33.33333%;
}
.gc--2-of-3 {
  width: 66.66666%;
}
.naccs {
  position: relative;
  /* max-width: 900px; */
  /* margin: 100px auto 0; */
}
.naccs .menu div {
  padding: 15px 20px 15px 40px;
  margin-bottom: 10px;
  color: #fff;
  background: #044563;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  position: relative;
  vertical-align: middle;
  font-weight: 700;
  transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
}
.naccs .menu div:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.naccs .menu div span.light {
  height: 0px;
  width: 0px;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: transparent;
  border-radius: 0%;
  transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
}
.naccs .menu div.active span.light {
  background-color: #fbc02d;
  left: 0;
  height: 100%;
  width: 3px;
  top: 0;
  border-radius: 0;
}
.naccs .menu div span.menuHead {
  margin-left: -20px;
}
.naccs .menu div.active span.menuHead {
  margin-left: 0px;
}
.naccs .menu div.active span.menuHeadSub {
  display: contents;
  font-size: 12px;
  font-family: Verdana, Arial, Helvetica, sans-serif;
  color: #fddf96;
  font-weight: 100;
}
.naccs .menu div span.menuHeadSub {
  display: none;
  font-size: 12px;
  font-family: Verdana, Arial, Helvetica, sans-serif;
  color: #fddf96;
  font-weight: 100;
}
.naccs .menu div.active {
  color: #fbc02d;
  padding: 15px 20px 15px 20px;
}
ul.nacc {
  position: relative;
  height: 0px;
  list-style: none;
  margin: 0;
  padding: 0;
  transition: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1);
  color: #fff;
}
ul.nacc li {
  opacity: 0;
  transform: translateX(50px);
  position: absolute;
  list-style: none;
  transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
}
ul.nacc li.active {
  transition-delay: 0.3s;
  z-index: 2;
  opacity: 1;
  transform: translateX(0px);
}
ul.nacc li p {
  margin: 0;
}
ul.nacc img {
  width: 803px;
  height: 437px;
}
#virRepSec {
  padding: 20px 90px;
  background-color: #01141d;
  min-height: 90em;
}

#fccSlider .react-slideshow-container {
  display: flex;
  align-items: center;
  text-align: center !important;
  // margin: 117px !important;
  padding-top: 9em;
}
.section-title-about h2 {
  font-size: 30px;
  font-weight: 700;
  padding: 0;
  line-height: 1px;
  margin: 0 0 5px 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #fff;
  font-family: 'Poppins', sans-serif;
}

.imageDialog .MuiDialog-paperWidthSm {
  max-width: 235vh !important;
}
// .dialog {
//   box-shadow: 0 8px 6px -6px black;
//   position: static;
//   left: 20%;
//   top: 10%;
// }

// .image {
//   width: 300px;
// }
