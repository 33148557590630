.authLayoutHeader b {
    font-family: Lato;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    color: black;
  }
  
  .authLayoutHeader p {
    font-family: Lato;
    font-size: 25px;
    font-style: italic;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    color: #006c67;
  }
  
  .link-text-light {
    color: black;
    font-family: Lato;
  }
  
  .loginCardBody {
    padding: 1rem;
  }
  
  .loginCardFooter {
    text-align: center;
    padding-top: 1em;
  }
  
  .forgetPasswordRow {
    padding: 1em;
  }
  
  .passwordLink {
    font-weight: 600;
    cursor: pointer;
    color: black !important;
  }
  #loginButton {
    color: #fff;
    margin: auto;
    font-size: 15px;
  }
  
  .ptsButton {
    padding: 0em;
    background-color: rgb(0, 108, 103);
    color: rgb(255, 255, 255);
    margin: auto;
    width: 110px;
    height: 39px;
    font-size: 17px;
    text-transform: uppercase;
  }
  