.memberBadge {
    float: right;
    margin-right: 0.2em;
    margin-top: 0.2em;
}

.communicationBadge {
    float: right;
    margin-right: 1em;
    margin-top: 0.2em;
}

.transportationBadge {
    float: right;
    margin-right: 1.1em;
    margin-top: 0.2em;
}

.homePageSection {
    padding: 0px 0 !important;
}

.questionList {
    list-style: disc;
}

.upcomingApp .MuiList-padding {
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: -15px;
}


.upcomingApp .MuiPopover-paper {
    outline: 0;
    position: absolute;
    max-width: calc(100% - 32px);
    min-width: 16px;
    max-height: calc(100% - 32px);
    min-height: 16px;
    overflow-x: none;
    overflow-y: none;


}

.upcomingCommunication .MuiList-padding {
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: -15px !important;
}

.upcomingCommunication .MuiPopover-paper {
    outline: 0;
    position: absolute;
    max-width: calc(100% - 32px);
    min-width: 16px;
    max-height: calc(100% - 32px);
    min-height: 16px;
    overflow-x: none;
    overflow-y: none;

}

.upcomingTransportation .MuiList-padding {
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: -15px;
}


.upcomingTransportation .MuiPopover-paper {
    outline: 0;
    position: absolute;
    max-width: calc(100% - 32px);
    min-width: 16px;
    max-height: calc(100% - 32px);
    min-height: 16px;
    overflow-x: none;
    overflow-y: none;


}

#upCommingAppointments .table {
    width: 100%;
    margin-bottom: 0rem;
    color: #212529;
}

.memberAppointmentHeading {
    margin: 2px;
    font-weight: bold !important;
}

.memberAppointments p {
    margin: 2px;
    font-weight: normal;
    font-size: smaller;
    color: #000;
}
.memberEventHeading{
        margin: 2px;
        font-weight: bold !important;
    }
.memberEvent p {
    margin: 2px;
    font-weight: normal;
    font-size: smaller;
    color: #000;
}
.memberIncidentHeading {
    margin: 2px;
    font-weight: bold !important;
}

.memberIncident p {
    margin: 2px;
    font-weight: normal;
    font-size: smaller;
    color: #000;
}

.memberCommunicationHeading {
    margin: 2px;
    font-weight: bold !important;
}

.memberCommunication p {
    margin: 2px;
    font-weight: normal;
    font-size: smaller;
    color: #000;
}

.memberTransportationHeading {
    margin: 2px;
    font-weight: bold !important;
}

.memberTransportation p {
    margin: 2px;
    font-weight: normal;
    font-size: smaller;
    color: #000;
}

.menuList {
    list-style: none !important;
    display: contents;
}

.menuCard {
    height: 150px;
    border-radius: 5px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2) !important;
}

#menuIcon {
    font-size: 40px;
    color: #fff;
    transition: transform 0.7s ease-in-out;
}

// :hover .fa-stack {
//   transform: rotate(45deg);
//   transition: all 0.25s ease-in-out;
// }

#menuIcon:hover {
    transform: scale(1.991);
}

.helpButton {
    float: right;
    z-index: 1000;
    margin: 1em;
    bottom: 6px;
    right: 6px;
}

.mrRequestWelcome .MTableToolbar-title {
    overflow: visible !important;
}

.subscriptionModal .MuiDialog-paper {
    min-width: 500px !important;
}

.messageNotification td {
    /* padding: 10px 20px; */
    border: 1px solid #eceff2;
    border-radius: 3px;
    vertical-align: middle;
    padding: 3px 10px !important;
}

.appointmentNotification td {
    /* padding: 10px 20px; */
    border: 1px solid #eceff2;
    border-radius: 3px;
    vertical-align: middle;
    padding: 3px 10px !important;
}

@media screen and (max-width: 768px) {
    .homeHeder {
        min-height: 3em;
        align-items: flex-end;
        box-shadow: 1px 1px 5px #524f4f;
        background: #fff;
        padding: 0.5em;
        margin-top: -1.2em;
        background-image: linear-gradient(#fff 0%, #f1eded 100%);
        position: fixed;
        width: 105%;
        z-index: 2;
    }
}
#appointmentIcon{
    background-Image: -webkit-linear-gradient(bottom, #843b07 0%, #f7b571 100%);
}

#communicationIcon{
    background-Image: -webkit-linear-gradient(bottom, #843b07 0%, #f7b571 100%);
}
#eventIcon{
background-Image: -webkit-linear-gradient(bottom, #843b07 0%, #f7b571 100%);
}

#incidentIcon{
    background-Image: -webkit-linear-gradient(bottom, #843b07 0%, #f7b571 100%);
}

#transportationIcon{
    background-Image: webkit-linear-gradient(bottom, #843b07 0%, #f7b571 100%);
}

#simple-menu4 .MuiList-padding {
     padding-top: 0px !important;
     padding-bottom: 0px !important;
}
#notificationBadge .MuiBadge-badge {
        color: rgb(255, 255, 255);
            background-color: limegreen;
            border: 1px solid rgb(255, 255, 255);
}

#simple-menu5 .MuiList-padding {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

#notificationBadge  {
    float: right;
margin-right: 0.2em;
margin-top: -0.3em;

}

