#tableStyle {
  color: black !important;
  border: 1px solid black !important;
  font-size: 0.6em;
}

.faIcon {
  font-size: 1.6em;
  color: #162c50;
  // margin-top: 0.6em;
}
.noteTextArea {
  margin: -1.5em 0 -1.5em 0.5em;
  width: 100%;
}
.addReminder {
  float: right;
  font-size: 1.3em;
  color: #162c50;
}
.appointmentCardView {
  // height: 16.5em !important;
  padding: 15px;
  background: #e8f6f3 !important;
  border-radius: 20px !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2) !important;
}
.appointmentDoctorCardView {
  // height: 16.5em !important;
  width: 100%;
  background: #e8f6f3 !important;
  border-radius: 20px !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2) !important;
}
.appointmentCard {
  height: 16.5em !important;
  padding: 15px;
  background: #e8f6f3 !important;
  border-radius: 20px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2) !important;
}
.taskTable {
  font-size: 11px;
}
.appointmentLink {
  color: #3277f2;
  cursor: pointer;
}
.doctorHeader {
  margin-left: 3.6em;
  margin-top: -1em;
}
.ptsAvatar {
  background: #e8f1e4 none repeat scroll 0% 0% !important;
  width: 25px !important;
  height: 25px !important;
}
.labelHeader {
  color: #7d88ab;
}
.reminderLabel {
  color: #7d88ab;
  margin-bottom: -0.5em !important;
}
.MuiAccordionDetails-root {
  display: block !important;
  padding: 8px 16px 16px;
}
.MuiAccordionSummary-content {
  margin: 12px 0;
  display: block !important;
  flex-grow: 1;
  transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.accordionText {
  margin-left: 0.1em !important;
}
.facilityTransportationCard {
  background: beige !important;
  border-radius: 15px !important;
}

.appointmentDate {
  height: 56px;
  width: 100%;
  padding: 1em;
  border-radius: 3px;
  border: 1px solid #c4c4c4;
  overflow: hidden !important;
}

.rdtPicker {
  position: fixed !important;
}

.react-datepicker-wrapper {
  width: 100% !important;
}
.react-datepicker__input-container input {
  width: 100% !important;
  padding: 1em;
  height: 46px;
  border-radius: 0px;

  border: 1px solid rgb(196, 196, 196) !important;
}

.react-datepicker__header {
  background-color: #005476;
  color: '#fff' !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: #fff;
}

.react-datepicker__navigation--next {
  border-left-color: #fff;
}

.react-datepicker__navigation--previous {
  border-right-color: #fff;
}

.react-datepicker__day-name {
  color: #fff;
}

.react-datepicker__input-time-container {
  margin: 5px 0px 0px 0px;
  padding-left: 1.2em;
  background-color: #f1c40f;
  color: #000;
  font-size: 17px;
}
.appointment_section_header {
  font-weight: 600 !important;
  color: rgb(0, 153, 241) !important;
}

.react-datepicker-popper {
  z-index: 11 !important;
}

.ptsDateTimePicker.MuiPickerDTTabs-tabs {
  color: #fff;
  background-color: #005476 !important;
}

// .ptsDateTimePicker.MuiTypography-h3 {
//   font-size: 2.3rem !important;

// }
// #ptsDateTimePicker .MuiTypography-h3 {
//   font-size: 2rem !important;
//   font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//   font-weight: 400;
//   line-height: 1.167;
//   letter-spacing: 0em;
// }
#ptsDateTimePicker .MuiTypography-h3 {
  font-size: 2rem !important;

}