.referenceAppointmentList .MuiPaper-elevation2 {
  box-shadow: 10px 10px 10px #fff;
}
.referenceAppointmentView .card {
  background: #d6eaf8;
}
.referenceAppointmentView .card-header {
  border-bottom: 1px solid #dcdcdc;
}
.referenceAppointmentEdit .card {
  background: #d6eaf8;
}
.referenceAppointmentCreate .card {
  background: #d6eaf8;
}

.refAppointmentLabel {
  font-weight: bold;
  color: '#000';
}

.transportationAppointmentList .table.table thead {
  background: #1ABC9C !important;
}

.transportationAppointmentList .table.table thead th {
  font-weight: 600;
  padding: 8px 20px;
  text-transform: capitalize;
   border: 1px solid #1ABC9C !important;
  border-radius: 3px;
  color: #fff !important;
  background: #1ABC9C !important;
}
.transportationAppointmentList .table.table tbody tr {
  border: 2px solid lightgray;
  cursor: pointer;
}
.referenceAppointmentList .table.table thead {
  background: #1ABC9C;
}
.referenceAppointmentList .table.table thead th {
  font-weight: 600;
  padding: 8px 20px;
  text-transform: capitalize;
   border: 1px solid #1ABC9C !important;
  border-radius: 3px;
  color: #fff;
}
.referenceAppointmentList .table.table tbody tr {
  border: 2px solid lightgray;
  cursor: pointer;
}