.applicationFooter {
  color: #fff;
  background-color: #000;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  overflow: hidden;


}

#menuIconStyle{ font-Size: 20px}
#menuNameStyle{font-size: 15px;
  }