.location_details_view .panel {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  // -webkit-box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
  // box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
}
.location_details_view .panel-blue {
  border-color: #e9f7ef !important;
}

.location_details_view .panel {
  margin-bottom: 20px;
  background-color: #fff;
  // border: 1px solid transparent;
  border: 5px solid #3498DB;
  border-radius: 4px;
  // -webkit-box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
  // box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
}
.location_details_view .panel-blue .panel-heading {
  border-color: #f2fbf699;
  color: #000;
  min-height: auto;
  background-color: #f2fbf699;
}
.location_details_view .panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.location_details_view .panel-footer {
  // padding: 10px 15px;
  padding: 5px 15px;
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
