@media screen and (min-width: 992px) {
  .user-panel .memberIcon {
    width: 50px !important;
    height: 50px !important;
  }
}

.memberIcon {
  width: 60px !important;
  height: 60px !important;
}
