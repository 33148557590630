.countStatMonth .MuiTableCell-root{

        border: 1px solid #EAEDED;
        text-align: right !important;
}
.countStatMonth .MuiTableCell-head {

    text-align: right;
}
.staticBtn{padding: 0em 1em 1em;
    border-top: none !important;
    margin-left: auto;
    margin-right: auto;}