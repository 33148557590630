.adminLoginHeader b {
    font-family: Lato;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    text-align: center;
    color: black;
  }
  
  .adminLoginHeader p {
    font-family: Lato;
    font-size: 25px;
    font-style: italic;
    font-weight: 700;
    line-height: 36px;
  
    text-align: center;
    color: #006c67;
  }
  
  .link-text-light {
    color: black;
    font-family: Lato;
  }
  
  .loginCardBody {
    padding: 1rem;
  }
  
  .loginCardFooter {
    text-align: center;
    padding-top: 1em;
  }
  
  .forgetPasswordRow {
    padding: 1em;
  }
  
  .passwordLink {
    font-weight: 600;
    cursor: pointer;
    color: black !important;
  }
  
  #loginButton{
    padding: 0.5em;
     background-Color: #ef6603;
     color: #fff;
      margin: auto
  }