#iconStyle{
        padding: 10px;
            text-align: center;
            display: inline-flex;
            align-items: center;
            justify-content: center;
}
#headerIcon{
    font-size: 21px;
}
.navCarBody{
    padding: 10px;
}