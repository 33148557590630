.eventTab .tab-content {
  background: transparent;
  padding: 0px;
  margin: 0em 0em 0em 0.4em;
}

.eventTab .nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  background: #005476;
  color: #fff;
  margin: 5px;
}

