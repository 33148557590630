.communicationTab .nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  background: #005476;
  color: #fff;
  margin: 5px;
}
.communicationTab .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.communicationTab .tab-content {
  background: transparent;
  padding: 0px;
  margin: 0em 0em 0em 0.4em;
}

.communicationTab .MuiTableRow-root.MuiTableRow-hover:hover {
  background-color: lightgrey !important;
}
