.eventDateFormate .react-datepicker-popper {
  z-index: 2 !important;
}

.view_eventNote {
  overflow-x: none !important;
  padding: 0;
  padding: 3px;
  white-space: pre-line;
  white-space: pre-wrap;
  max-height: 250px;
  display: block;
  // padding: 9.5px;
  // margin: 0 0 10px;
  font-size: 14px;
  line-height: 1.42857;
  // word-break: break-all;
  word-break: keep-all;
  word-wrap: break-word;
  color: #000;
  background-color: transparent !important;
  overflow-x: auto;
  border: none !important;
  font-family: 'sans-serif', sans-serif !important;
}

// #participantTable .table th,
// .table td {
//   padding: 6px;
// }
