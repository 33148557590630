// @import '../node_modules/@fullcalendar/core/main.css';
.App {
  font-family: sans-serif;
  text-align: center;
  color: black !important;
}

.calender {
  color: black !important;
}

.fc-day-number {
  font-size: 1.5em;
  color: #5c005c;
}

/* override day title color and size */
.fc-day-header {
  font-size: 1.5em;
  color: #00b294 !important;
}

// h2,
// h3,
// h4 {
//   color: #1b4f72 !important;
// }
.fc-widget-header {
  background-color: blue !important;
}

.fc .fc-daygrid-day-number {
  // font-size: 1.5em;
  color: #5c005c;
}

.fc-theme-standard td,
.fc-theme-standard th {
  border: 1px solid gray;
  border: 1px solid gray;
  border: 1px solid var(--fc-border-color, gray);
  height: 1em;
}

/* override day title color and size */
.fc .fc-col-header-cell-cushion {
  // font-size: 1.5em;
  padding: 0.5em;
  color: #fff;
  background: #1abc9c;
}

.fc .fc-toolbar.fc-header-toolbar {
  height: 50px;
  color: #1b4f72 !important;
  margin-bottom: 1.5em !important;
}

.fc-theme-standard .fc-scrollgrid {
  border: 1px solid gray;
  border: 1px solid gray;
  border: 1px solid var(--fc-border-color, gray);
}

.fc-direction-ltr {
  direction: ltr;
  text-align: left;
  padding: 2em !important;
}

.fc-col-header {
  background: #1abc9c;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: var(--fc-today-bg-color, rgba(86, 101, 115, 0.15)) !important;
}

.fc .fc-scrollgrid-section table {
  height: 2em;
}

// .MuiTableCell-root {
//   display: table-cell;
//   padding: 8px !important;
//   padding-right: 8px;
//   font-size: 0.875rem;
//   text-align: left;
//   font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
//   font-weight: 400;
//   line-height: 1.43;
//   border-bottom: 1px solid rgba(224, 224, 224, 1);
//   letter-spacing: 0.01071em;
//   vertical-align: inherit;
// }

.MuiToolbar-regular {
  min-height: 50px !important;
}

.fc-event-dot {
  background-color: #1b4f72 !important;
}

.breadcrumbLink {
  color: #4a4a4a;
  background-color: #fff !important;
}

.fc-h-event .fc-event-main {
  height: 35px;
  color: #000;
}

.fc-timegrid-event .fc-event-main {
  padding: 1px 1px 0;
  color: #000;
}

// .fc-time-grid .fc-slats td {
//   height: 3.5em;
// }
// .fc-next-button {
//   width: 4em;
// }
// .fc-timeGridDay-button {
//   width: 5em;
// }
.fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active {
  color: #1a252f;

  background-color: #d6eaf8;
  border-color: #151e27;
}

.fc .fc-button-primary {
  color: #2c3e50;
  background-color: #fad7a0;
  border-color: #2c3e50;
}

.floatquickButton {
  min-height: 3em !important;
  align-items: flex-end;

  padding: -0.5em;
  margin-top: -1.2em;
  float: right;
  position: fixed !important;
  width: 95%;
  z-index: 11;
}
#adminPanelCalender .fc-v-event {
  width: 350px;
  margin: 0.5em;
  border: none !important;
  border: none !important;
  border: none !important;
  background-color: transparent !important;
  background-color: transparent !important;
}

.consolidatedDayView .MuiTableCell-head {
  // border: 1px solid #000!important
  border-right: 1px solid !important;
  border-bottom: 1px solid !important;
}

// .consolidatedDayView .MuiTableCell-root {
//     border: 1px solid gray !important
// }
.consolidatedDayView .MuiTableCell-stickyHeader {
  background-color: #b2babb !important;
}
.currentDayCol {
  text-align: center;
  color: #000;
}
.selectDateBtn {
  background: #ef6603;
  color: white;
  text-transform: capitalize;
  cursor: pointer;
  float: right;
  margin: 0.2em 0.5em 0.3em 0em;
}
#classicViewBtn {
  background: #c0392b !important;
  color: white;
  text-transform: capitalize;
  cursor: pointer;
  float: right;
  font-size: medium;
  // margin: 0.2em 0.5em 0.3em 0em;
}

#filterBtn {
  background: rgb(239, 102, 3);
  color: rgb(255, 255, 255);
  margin-top: 7px;
  text-transform: capitalize;
  cursor: pointer;
  height: 42px;
  width: 30%;
  font-size: medium;
}
#resetBtn {
  background: #000;
  color: rgb(255, 255, 255);
  margin-top: 7px;
  text-transform: capitalize;
  cursor: pointer;
  height: 42px;
  width: 30%;
  font-size: medium;
}
#printBtn {
  background: #3498db;
  color: rgb(255, 255, 255);
  text-transform: capitalize;
  cursor: pointer;
  min-height: 32px;
  // width: 25%;
  float: right;
  font-size: medium;
  margin: 0em 0.5em 0.3em 0em;
}

#excelBtn {
  background: rgb(90, 196, 137);
  color: rgb(255, 255, 255);
  text-transform: capitalize;
  cursor: pointer;
  min-height: 32px;
  margin: 0em 0.5em 0.3em 0em;
  float: right;
  font-size: medium;
}
#nextWeekBtn {
  // border-radius: 8%;
  //     background: rgb(0, 0, 0);
  //     color: white;
  //     font-size: 2px;
  //     width: 47px;
  //     min-height: 38px;
  //     padding-left: 0.9em;
  //     float: left;
  //     cursor: pointer;
  border-radius: 8%;
  background: black;
  color: white;
  font-size: 2px;
  width: 30px;
  min-height: 32px;
  padding-left: 3em;
  cursor: pointer;
}

#previousBtn {
  border-radius: 8%;
  background: black;
  color: white;
  font-size: 2px;
  width: 30px;
  min-height: 32px;
  padding-left: 3em;
  float: right;
  cursor: pointer;
}
::-webkit-scrollbar {
  /* <-- This sets the thickness of the VERTICAL scrollbar */
  height: 7px;
  /* <-- This sets the thickness of the HORIZONTAL scrollbar */
}

.transform-component-module_wrapper__1_Fgj {
  position: relative;
  width: 100% !important;
}

.transform-component-module_content__2jYgh {
  /* display: flex; */
  /* flex-wrap: wrap; */
  width: 100% !important;
  /* width: fit-content; */
  height: none !important;
  /* height: fit-content; */
  margin: 0;
  padding: 0;
  transform-origin: 0% 0%;
}

#weekCell.MuiTableCell-root {
  padding: 0px !important;
}
#weekCell .MuiTableBody-root {
  display: none !important;
}

#weekCell.MuiTableCell-root {
  border: 1px solid black !important;
  padding: 0px !important;
}
#weekBody .MuiTableHead-root {
  display: none !important;
}
// #weekTableHead .MuiTableCell-root {
// border:1px solid #000 !important
// }
// .weekViewTable .TableRow .TableCell{
//         border: 1px solid #000 !important
//     }
.weekViewTable .MuiTableCell-root {
  // border-bottom: none !important;
  // border-right: none !important;
  border: 1px solid;
}
.weekViewTable .MuiTableCell-head {
  // border: 1px solid #000!important
  border-right: none !important;
  border-bottom: none !important;
}

.userLoginCount .MuiBadge-badge {
  font-size: 0.7rem;
  margin-right: 8px;
  height: 18px;
  width: 18px;
}
