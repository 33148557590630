datalist {
    position: absolute;
    max-height: 20em;
    border: 0 none;
    overflow-x: hidden;
    overflow-y: auto;
}

datalist option {
    font-size: 0.8em;
    padding: 0.3em 1em;
    background-color: #ccc;
    cursor: pointer;
}

datalist option:hover,
datalist option:focus {
    color: #fff;
    background-color: #036 !important;
    outline: 0 none;
}