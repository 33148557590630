#notificationFormLabel{
text-align: left;
}
#notificationMessage{
width: 100%;
padding: 10px;
margin: 1em 0.9em 0em 0.9em;
height: 137px !important;
border: 1px solid lightgray;
border-radius: 4px;
}

.labelColumn{
    text-align: right;
    margin: auto;
}
#submitBtn {
float: left;
 height: 100%;
align-items: center;
}

.copyIcon  {
height: 0.8em !important;
cursor: pointer;
}

.listColumn{
list-style: none;
}
.emailBody{
padding: 2em;
    border: 1px solid lightgrey;
}