.RegistrationTitle {
  text-align: center;
  // font-weight: bold;
  color: #fff !important;
  margin-top: 0.5em;
  padding: 0.2em;
}

.table {
  color: #000 !important;
  // font-weight: bold !important;
}

.tableContents {
  font-weight: bold !important;
  text-align: left;
  font-size: 13px;
}

table.table tbody td {
  border: none !important;
}

table.table tbody tr {
  border: 2px solid lightgray;
}

// :-webkit-scrollbar {
//   -webkit-appearance: none;
//   width: 10px;
// }
// ::-webkit-scrollbar-thumb {
//   border-radius: 4px;
//   background-color: #000;
//   -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
// }

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}