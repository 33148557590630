.purchaseButton .MuiIconButton-root {
  flex: 0 0 auto;
  color: rgba(0, 0, 0, 0.54);
  // padding: 12px;
  overflow: visible;
  font-size: 1.5rem;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
  padding: 0px;
}

.purchase_List .MuiTableRow-root.MuiTableRow-hover:hover {
  background-color: lightgrey !important;
}
.reminderBadge{
  margin:0.2em 0.6em !important ;
}
