.addLocation {
  float: right;
  font-size: 1.3em;
  color: #162c50;
}

.facilityCard {
  background: #fff !important;
  border-radius: 15px !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}
.facilityLocationCard {
  background: #fff !important;
  border-radius: 15px !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.24) !important;
}

.view_facilityDesc {
  overflow-x: none !important;
  padding: 0;
  padding: 3px 0px;
  white-space: pre-line;
  max-height: 250px;
  display: block;

  font-size: 14px;
  line-height: 1.42857;
  word-break: break-all;
  word-wrap: break-word;
  color: #000;
  background-color: transparent !important;
  overflow-x: auto;
  border: none !important;
  font-family: 'sans-serif', sans-serif !important;
}
