.planPriceDropDown {
  width: 80%;
  padding: 10px;
  border-radius: 6px;
}
.pricing-title p {
  margin: 0;
  margin: 0;
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif;
  color: #2a2c39;
}

.contactSection-title {
  padding-bottom: 40px;
}

.pricing-title h2 {
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  line-height: 1px;
  margin: 0 0 5px 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #aaaaaa;
  font-family: 'Poppins', sans-serif;
}

.pricing-title h2::after {
  content: '';
  width: 120px;
  height: 1px;
  display: inline-block;
  background: #fd9042;
  margin: 4px 10px;
}

.pricingModal .modal-content {
  padding: 0px !important;
}
.pricingModal ul {
  list-style: disc;
}
.pricingModal p {
  font-size: 17px;
}

.pricingModal .modal-title {
  margin-left: auto;
}

#price {
  text-align: center;
}

.plan {
  display: inline-block;
  margin: 10px 1%;
  font-family: 'Lato', Arial, sans-serif;
}

.plan-inner {
  background: #fff;
  margin: 0 auto;
  min-width: 280px;
  max-width: 100%;
  position: relative;
  min-height: 549px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.entry-title {
  background: #53cfe9;
  height: 140px;
  position: relative;
  text-align: center;
  color: #fff;
  margin-bottom: 30px;
}

.entry-title > h3 {
  background: #20bada;
  font-size: 20px;
  padding: 5px 0;
  text-transform: uppercase;
  font-weight: 700;
  margin: 0;
}

.entry-title .price {
  position: absolute;
  bottom: -25px;
  background: #20bada;
  height: 95px;
  width: 95px;
  margin: 0 auto;
  left: 0;
  right: 0;
  overflow: hidden;
  border-radius: 50px;
  border: 5px solid #fff;
  line-height: 80px;
  font-size: 28px;
  font-weight: 700;
}

.price span {
  position: absolute;
  font-size: 23px;
  bottom: 4px;
  left: 30px;
  font-weight: 400;
}

.entry-content {
  color: #323232;
}

.entry-content ul {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
}

.entry-content li {
  border-bottom: 1px solid #e5e5e5;
  padding: 10px 0;
}

.entry-content li:last-child {
  border: none;
}

#freeWithAdd .entry-content li:last-child{
  border: 1px solid #e5e5e5;
}
.plan-inner_free_plan_button_plan_card_bottom {
  padding: 2em 0 2em 0;
  text-align: center;
    margin-top: 4em;
}

.plan-inner_free_plan_button_plan_card_bottom a {
  background: #75ddd9;
  padding: 10px 30px;
  color: #fff !important;
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
}

.plan-inner_basic_plan_button_plan_card_bottom {
  padding: 2em 0 2em 0;
  text-align: center;
}

.plan-inner_basic_plan_button_plan_card_bottom a {
  background: #dd4b5e;
  padding: 10px 30px;
  color: #fff !important;
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
}

.plan-inner_business_plan_button_plan_card_bottom {
  padding: 0.5em 0 2em 0;
  text-align: center;
}

.plan-inner_business_plan_button_plan_card_bottom a {
  background: #3772aa;
  padding: 10px 30px;
  color: #fff !important;
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
}

.plan-inner_free_plan_card_plan_card_bottom .entry-title {
  background: #75ddd9;
}

.plan-inner_free_plan_card_plan_card_bottom .entry-title > h3 {
  background: #44cbc6;
}

.plan-inner_free_plan_card_plan_card_bottom .price {
  background: #44cbc6;
}

.plan-inner_business_plan_card_plan_card_bottom .entry-title {
  background: #4484c1;
}

.plan-inner_business_plan_card_plan_card_bottom .entry-title > h3 {
  background: #3772aa;
}

.plan-inner_business_plan_card_plan_card_bottom .price {
  background: #3772aa;
}

.plan-inner_basic_plan_card_plan_card_bottom .entry-title > h3 {
  background: #dd4b5e;
}

.plan-inner_basic_plan_card_plan_card_bottom .entry-title {
  background: #f75c70;
}

.plan-inner_basic_plan_card_plan_card_bottom .price {
  background: #dd4b5e;
}

#price {
  text-align: center;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
}

.plan {
  display: inline-block;
  margin: 10px 1%;
  font-family: 'Lato', Arial, sans-serif;
}
// .plan-inner_free_plan_card_plan_card_bottom {
//   background-color: #44cbc6;
// }
// .plan-inner_basic_plan_card_plan_card_bottom {
//   background-color: #dd4b5e;
// }
