.activeMemberCard{
    margin: 0.2em;
width: 250px !important;
cursor: pointer;
box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2) !important;
}
.activityCardBody p{
    margin-bottom: 0.2em;
}

.activityCardBody  {
    padding: 0.5em !important;
}
.activityTypeCol p{
    text-align: right;
        text-transform: capitalize;
        margin-bottom: 1px;
        color: rgb(0, 0, 0);

}
.memberNameCol p{
    text-align: left;
        text-transform: capitalize;
        margin-bottom: 1px;
        color: rgb(0, 0, 0);
}
.dateTimeRow{
        width: 100%;
            color: rgb(0, 0, 0);
            white-space: normal;
            margin-top: 0.1em;
            padding: 0em 0.5em;
}

// #calenderEventCard .MuiTableHead-root {
//     display: none !important;
// }
#weekTableBody .MuiTableCell-root {
    border-bottom: 3px solid #fff !important;
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
}
#dayRow .MuiTableCell-root {
    border-bottom: 3px solid #fff !important;
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
}