
.ROOT_CSS {
  height: 100% !important;
  width: 100% !important;
}

.communication-box-holder {
  width: 100%;
  margin: 0 0 15px;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  white-space: pre-wrap;
  word-break: keep-all;
}

.communication-box {
  padding: 6px 10px;
  border-radius: 6px 0 6px 0;
  position: relative;
  background: #d6eaf8;
  border: 2px solid rgba(100, 170, 0, 0.1);
  color: #000;
  font-size: 12px;
  width: 80%;
}

.message-partner {
  background: #fff;
  border: 2px solid rgba(0, 114, 135, 0.1);
  align-self: flex-start;
  white-space: pre-wrap;
  word-break: keep-all;
}
.chatBox-messages {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  padding: 10px;
  overflow: auto;
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  flex: 1;
  background-image: url('../../../assets/images/window.png');
}

.chat-box-holder {
  width: 100%;
  margin: 0 0 15px;
  display: flex;
  flex-flow: column;
  align-items: flex-end;
}

#participantMenuList .table {
  width: 100%;
  margin-bottom: 0rem;
  color: #212529;
}

#participantMenuList .MuiList-padding {
  padding-top: 0px;
  padding-bottom: 0px;
}



.appointmentDetail .modal-content {
  border-radius: 4px;
  padding: 0px !important;
  background: #f2f4f4;
}

.view_communication {
  overflow-x: none !important;
  padding: 0;
  padding: 3px;
  white-space: pre-line;
  max-height: 250px;
  display: block;

  font-size: 14px;
  line-height: 1.42857;
  word-break: keep-all;
  word-wrap: break-word;
  color: #000;
  background-color: transparent !important;
  overflow-x: auto;
  border: none !important;
  font-family: 'sans-serif', sans-serif !important;
}

.react-scroll-to-bottom--css-ixnta-1tj0vk3 {
  background: black !important;
  border-radius: 10px;
  border-width: 0px;
  bottom: 5px;
  cursor: pointer;
  height: 20px;
  outline: 0px;
  position: absolute;
  right: 20px;
  width: 20px;
}
