.addReminder {
  float: right;
  font-size: 1.3em;
  color: #162c50;
}
.appointmentCard {
  height: 12em !important;
  padding: 15px;
  background: #d5d8dc !important;

  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2) !important;
}
.taskTable {
  font-size: 11px;
}
.appointmentLink {
  color: #87a8de;
  cursor: pointer;
}
.doctorHeader {
  margin-left: 2.8em;
  margin-top: -1em;
}
.ptsAvatar {
  background: #e8f1e4 none repeat scroll 0% 0% !important;
  width: 25px !important;
  height: 25px !important;
}
.labelHeader {
  color: #7d88ab;
}
.reminderLabel {
  color: #7d88ab;
  margin-bottom: -0.5em !important;
}

.reminderDate {
  height: 56px;
  width: 100%;
  padding: 1em;
  border-radius: 3px;
  border: 1px solid #c4c4c4;
  overflow: hidden !important;
}

.rdtPicker {
  position: fixed !important;
}

.reminder_details_view .panel {
  margin-bottom: 20px;
  background-color: #fbe2e3;
  border: 1px solid transparent;
  border-radius: 4px;
}
.reminder_details_view .panel-yellow {
  border-color: #fcf3cf !important;
}

.reminder_details_view .panel {
  margin-bottom: 20px;
  background-color: #fbe2e3;
  border: 1px solid transparent;
  border-radius: 4px;
}
.reminder_details_view .panel-yellow .panel-heading {
  color: #fff;
  min-height: 2em;
  padding: 6px;
  background-color: #fbe2e3;
  border-bottom: 1px solid #d5d8dc;
}
.reminder_details_view .panel-body {
  text-align: center;
  padding: 10px;
  min-height: 5em;
}
.reminder_details_view .panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.reminder_details_view .panel-footer {
  padding: 5px 15px;
  background-color: #fbe2e3 !important;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top: 1px solid #d5d8dc;
}

.ptsAvatar_note {
  background: rgb(19, 98, 105) !important;
  width: 25px !important;
  height: 25px !important;
}

.ptsAvatar_note i {
  color: #fff !important;
}
