#Ad01-h1 {
  font-size: 30px;
  font-weight: 700;
  margin-top: -12px;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #fff;
  text-align: center;
}
#Ad01-h2 {
  font-size: 15px;
  font-weight: 500;
  margin-top: -10px;
  text-transform: capitalize;
  letter-spacing: 1px;
  color: #fff;
  text-align: center;
}
#Ad01-header a {

  text-decoration: none;

  //   background: #313030;
  //   padding: 7px 15px;
  //   margin-top: -10px;
  //   border-radius: 5px;
  font-size: 13px;
}
#Ad01-p {
  font-size: 12px;
  margin-top: -5px;
  padding: 0 15%;
  text-align: center;
  color: #fff;
}
#Ad01-header {
  // background: url("./bgAd01.jpg");
  padding: 1em;
  min-height: 120px;
  width: 100%;
  background-size: cover;
  -webkit-background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -o-flex;
}


.textRow .MuiInputBase-input {
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  height: 1.5em;
  margin: 0;
  display: block;
  padding: 6px 0 7px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
}

.textRow .MuiInputLabel-outlined {
  z-index: 1;
  transform: translate(14px, 20px) scale(1);
  pointer-events: none;
  margin-top: -0.5em;
}