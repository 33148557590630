.ptsHeader {
  min-height: 3em;
  align-items: flex-end;
  box-shadow: 1px 1px 5px #524f4f;
  background: #fff;
  padding: 0.5em;
  margin-top: -1.2em;
  background-image: linear-gradient(#fff 0%, #f1eded 100%);
  position: fixed;
  width: 87%;
  z-index: 60;
}

@media screen and (min-width: 992px) {
  .side-menu-collapsed .ptsHeader {
    min-height: 3em;
    align-items: flex-end;
    box-shadow: 1px 1px 5px #524f4f;
    background: #fff;
    padding: 0.5em;
    margin-top: -1.2em;
    background-image: linear-gradient(#fff 0%, #f1eded 100%);
    position: fixed;
    width: 95%;
    z-index: 11;
  }
}

.homeHeder {
  min-height: 3em;
  align-items: flex-end;
  box-shadow: 1px 1px 5px #524f4f;
  background: #fff;
  padding: 0.5em;
  margin-top: -1.2em;
  background-image: linear-gradient(#fff 0%, #f1eded 100%);
  position: fixed;
  width: 101%;
  z-index: 2;
}

// @media screen and (max-width: 992px) {
//   .ptsHeader {
//     min-height: 3em;
//     align-items: flex-end;
//     box-shadow: 1px 1px 5px #524f4f;
//     background: #fff;
//     padding: 0.5em;
//     margin-top: -1.2em;
//     background-image: linear-gradient(#fff 0%, #f1eded 100%);
//     position: fixed;
//     width: 105%;
//     z-index: 11;
//   }
// }
#calenderSync{
  margin-top: -0.4em;
  display: inline-flex !important;
  float: right !important;
}
.atcb-button{
   align-items: center;
   background-color: #717373 !important;
   border: 1px solid var(--btn-border);
   border-radius: 6px;
   box-shadow: var(--btn-shadow);
   color: #fff !important;
   cursor: pointer;
   display: flex;
   font-family: var(--font);
   font-size: 1em;
   font-weight: 600;
   justify-content: center;
   line-height: 1.5em;
   margin: 0.13em;
   max-width: 350px;
   min-width: 10em;
   padding: 0.65em 1em;
   position: relative;
   text-align: center;
   touch-action: manipulation;
   user-select: none;
   -webkit-user-select: none;
   -webkit-tap-highlight-color: transparent;
   width: auto;
   z-index: 1;
   height: 2.5em !important;
 }
 .add-to-calendar-button {
   border: none;
   background: #717373 !important;;
   color: rebeccapurple;
   font-weight: bold;
   text-decoration: underline;
   cursor: pointer;
 }
 .atcb-button-wrapper{
  border: none !important;
 }