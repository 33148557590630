.sponsorHeading {
  font-weight: bold;
  text-transform: capitalize;
  color: #6495ed;
  text-align: center;
  font-size: 28px;
  margin-bottom: 0em !important;
  position: relative;
  animation: mymove1 5s infinite;
}

@keyframes mymove1 {
  from {
    color: #d35400;
  }
  to {
    color: #000;
  }
}
.sponsorSubheading {
  font-weight: bold;
  text-transform: capitalize;
  text-align: center;
  position: relative;
  animation: mymove2 5s infinite;
}

@keyframes mymove2 {
  from {
    color: #2ecc71;
  }
  to {
    color: #8e44ad;
  }
}
.sponsorHeadingTemplate {
  font-weight: bold;
  text-transform: capitalize;
  color: #f39c12;
  text-align: center;
  font-size: 28px;
  margin-bottom: 0em !important;
}

.sponsorSubheadingTemplate {
  font-weight: bold;
  text-transform: capitalize;
  color: #34495e;
  text-align: center;
}

.templateText {
  position: relative;
  color: '#138D75';
  animation: mymove 5s infinite;
  color: #de3163;
  font-weight: bold;
}

@keyframes mymove {
  from {
    right: 0px;
  }
  to {
    right: 100px;
  }
}
@media screen and (max-width: 768px) {
  .templateText {
    position: relative;
    color: '#138D75';
    animation: mymove 5s infinite;
    color: #de3163;
    font-weight: bold;
  }
  @keyframes mymove {
    from {
      right: 0px !important;
    }
    to {
      right: 100px !important;
    }
  }
}
.templateCard {
  background-color: #eeabab;
  animation-name: example1;
  animation-duration: 4s;
  animation-iteration-count: infinite;
}

// @keyframes example1 {
//   from {
//     background-color: #fff;
//   }
//   to {
//     background-color: #fcb7b7;
//   }
// }

.sponsorHeadingTemplate {
  color: #f39c12;
  animation-name: example;
  animation-duration: 4s;
  animation-iteration-count: infinite;
}

@keyframes example {
  from {
    color: #e74c3c;
  }
  to {
    color: #e74c3c;
  }
}




